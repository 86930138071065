import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import {Container,Row,Col,ListGroup} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleRight} from '@fortawesome/free-solid-svg-icons'
import SEO from "../components/seo"

const mechanicalDesign =()=>{
    return(
        <Layout>
             <SEO title="Mechanical Design" />
            <div className="homeAuto">
                <Image alt="home automation" filename="mechanical.png" />
                <div className="mechanicalImage">
                    <h1 style={{fontWeight:"bold"}} className="mechH1">MECHANICAL DESIGN</h1>
                    <h3 className="mechH3">When your project demands the best. Your project demands Trace Width.</h3>
                    <p style={{fontSize:"20px"}} className="mechP">Let our skills and experience take flight for you.</p>
                </div>
            </div>

            <Container >
                <div className="manuPara">
                Trace Width Technology Solutions leading-edge engineers and machinists yield a suite of design and development services including associative design tool development, 3D printing, complex simulations, CNC fabrication, industrial design, new product design, as well as expeditious prototyping.
                </div>
                <Row className="mechaRow">
                    <Col sm={12} lg={6} ><Image alt="mechanical" filename="macah1.png" /></Col>
                    <Col sm={12} lg={6} className="manuPara1">
                    <   Image alt="mechanical" filename="caddesign.png" />
                        <p>Trace Width Technology Solutions is equipped with best-in-class technology and qualified CAD and CAE professionals, we aim to serve as a strategic engineering partner to manufacturing organizations and work towards the similar goal of achieving competitive product design.</p>
                        
                    </Col>
                </Row>
            </Container>

            <div className="homeForm">
                <h3 className="mecha3"><br/>We offer following services:</h3>
                <Container>
                    <Row>
                        <Col sm={12} lg={6} className="aboutCol">
                            <Image alt="test" filename="1_06.png" className="imgpic" />
                            <div>
                                <h3 className="homeh3">3D Modeling</h3>
                                <p className="homeautopara">Three Dimensional modeling of components and assemblies allow rapid simulation of fit checking, tolerancing, and motion.</p>
                            </div> 
                        </Col>

                        <Col sm={12} lg={6} className="aboutCol">
                            <Image alt="collaboration" filename="4_12.png"  className="imgpic" />
                            <div>
                                <h3 className="homeh3">Mechanical Proof of Concept</h3>
                                <p className="homeautopara">Trace Width has experience in designing mechanical components using 3D CAD modeling software, performing transient and steady state thermal analysis, shock and vibration analysis, and reliability analysis.</p>
                            </div> 
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={6} className="headerCol">
                            <Image alt="entrance" filename="3_13.png" className="imgpic1" />
                            <div>
                                <h3 className="homeh3">Reverse Engineering</h3>
                                <p className="homeautopara">Trace Width Technology Solutions is the number one choice for companies that need to archive, examine, rebuild, or alter existing devices and systems.</p>
                            </div> 
                        </Col>

                        <Col sm={12} lg={6} className="aboutCol">
                            <Image alt="collaboration" filename="2_08.png"  className="imgpic" />
                            <div>
                                <h3 className="homeh3">Machine Design</h3>
                                <p className="homeautopara">Our zealous team of engineers endeavour over decades of experience in custom machine design, using meticulous, high-accuracy parts to accommodate your particular details.</p>
                            </div> 
                        </Col>
                    </Row>
                </Container>
                <Image alt="home automation" filename="cadbg.png" />
            </div>
           
            <Container >
                <Row className="mechaRow">
                    <Col sm={12} lg={6} className="manuRow"><Image alt="home" filename="caddes1.png" /></Col>
                    <Col sm={12} lg={6} className="manuPara1">
                        <p>Trace Width Technology Solutions is a global engineering company supplying quality and cost effective CAD services. Trace Width delivers world-class technical support services of international standards through its well-trained and highly qualified workforce. Our clients can thus expect quality output, faster turn around and gain a dramatic boost to their competitive edge in the market.</p>
                    </Col>
                </Row>
            </Container>

            <Container >
                <Row >
                    <h3 className="manuh3">Our Detail Engineering Design Services Includes:</h3>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            <ListGroup variant="flush" className="listContent">
                                <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Schematics</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;M&E Drawings</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Fabrication Drawings</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Manufacturing Drawings</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Concept Drawings</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;3D CAD Modelling of Mechanical Components</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faAngleRight} size="1x" style={{ fontSize:"20px"}}/> &nbsp;&nbsp;Control systems drawings & logics</ListGroup.Item>
                                <ListGroup.Item></ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={12} lg={6}><Image alt="home" filename="104364605.jpg" /></Col>
                            <p className="mechpara">The success of Mechanical CAD Services can be simply attributed to the vast knowledge of our experts, decades of experience with the latest engineering technology & analytics. Of course, Trace Width not only offers you satisfactory, economic and advanced engineering solutions, we are also ready to share the same designing, analytical and drafting risks & challenges that are required to build-up long term relationships with partners.</p>
                </Row>
            </Container>
            <div>
                <Image alt="home automation" filename="mech-cad.png" />
            </div>
            <div className="headerForm"> 
               <br/>
              <div className="homeCardrow5">Ready to get your design off the ground? Contact Trace Width Technology Solutions today!</div>
              <ContactForm  />
             
          </div>  
        </Layout>
    )
}

export default mechanicalDesign;